import React, {useState} from 'react';
import { uploadImages } from './utils/images';

import {
    Button,
    Input, Loader
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useSession } from './SessionContext';

const ImagesUploadButton = () => {
    const {idToken} = useSession();
    const {location} = useSession();
    const [loading, setLoading]= useState(false);

    return (
        <>
            <Input style={{ display: 'none' }} id="imagesInput" type="file"
                   accept="image/jpeg,image/png,image/gif,image/webp,image/svg+xml,image/heif,image/heic,image/avif"
                   multiple={true}
                   onChange={
                       (event) => {
                           setLoading(true);
                           uploadImages({
                               accountId: idToken?.payload?.acc, files: event.target.files, location
                           }).then(() => {
                               setLoading(false);
                           }).catch((error) => {
                               console.error('Error uploading images', error);
                               setLoading(false);
                           })
                       }
                   }
            />
            <Button borderRadius="xl" disabled={loading} onClick={
                async () => {
                    document.getElementById('imagesInput').click();
                }
            } variation="primary"
            >{loading? <Loader size="small"/> : '📂'}</Button>
        </>
    );
}

export default ImagesUploadButton;
