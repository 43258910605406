import React, {useEffect, useState} from 'react';
import { MapView, LocationSearch } from '@aws-amplify/ui-react-geo';

import '@aws-amplify/ui-react-geo/styles.css';

import { useMap } from 'react-map-gl';
import {Button, useTheme} from '@aws-amplify/ui-react';

import { useSession } from './SessionContext';
import { addTransparency } from './utils/addTransparency';
import RecenterButton from "./RecenterButton";
import calculateZoomLevel from "./utils/location";


function Map() {
    const { tokens } = useTheme();
    const { location, fetchLocation } = useSession();

    const [searchToggle, setSearchToggle] = useState(true);

    useEffect(() => {
        const banner = document.getElementsByClassName('Banner')[0];
        if (banner) {
            banner.style.backgroundColor = addTransparency(tokens.colors.font.secondary.value, 0.5);
        }

        const searchInput = document.getElementsByClassName('maplibregl-ctrl-geocoder--input')[0];
        if (searchInput) {
            searchInput.style.display = searchToggle ? 'inline' : 'none';
        }

        if (!location) {
            fetchLocation();
        }

        const toggleSearchInput = () => {
            setSearchToggle(prevToggle => {
                const newToggle = !prevToggle;
                if (searchInput) {
                    searchInput.style.display = newToggle ? 'inline' : 'none';
                }
                return newToggle;
            });
        };

        const searchIcon = document.getElementsByClassName('maplibregl-ctrl-geocoder--icon-search')[0];
        if (searchIcon) {
            searchIcon.addEventListener('click', toggleSearchInput);
        }

        // Cleanup function to remove the event listener
        return () => {
            if (searchIcon) {
                searchIcon.removeEventListener('click', toggleSearchInput);
            }
        };
    }, [tokens, location, fetchLocation, searchToggle]);

    return (
    <MapView
        initialViewState={{
            latitude: location? location.latitude : 0,
            longitude: location? location.longitude : 0,
            zoom: location? calculateZoomLevel(location.accuracy) : 1
        }}
        style={{ width: '100%', height: '100vh' }}
    >
      <LocationSearch position="top-left" />
      {location? (
      <div style={{ position: 'absolute', top: '60%', right: '8px', zIndex: '+1' }}>
        <RecenterButton />
      </div>
      ):(
          <></>
      )}
    </MapView>
    );
}

export default Map;
