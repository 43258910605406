import React, {useState} from 'react';
import { uploadImages } from './utils/images';

import {Button, Input, Loader} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useSession } from './SessionContext';

const ImageCaptureButton = () => {
    const {idToken} = useSession();
    const {location} = useSession();
    const [loading, setLoading]= useState(false);

    return (
        <>
            <Input style={{ display: 'none' }} id="cameraInput" type="file" capture="environment"
                   accept="image/jpeg,image/png,image/gif,image/webp,image/svg+xml,image/heif,image/heic,image/avif" multiple={false}
                   onChange={
                    (event) => {
                       setLoading(true);
                       uploadImages({
                           accountId: idToken?.payload?.acc, files: event.target.files, location
                       }).then(() => {
                           setLoading(false);
                       }).catch((error) => {
                           console.error('Error uploading images', error);
                           setLoading(false);
                       })
                    }
                   }></Input>
            <Button borderRadius="xl" disabled={loading} onClick={
                async () => {
                    document.getElementById('cameraInput').click();
                    //const file = await navigator.mediaDevices.getUserMedia({video: true});
                    //console.log('File', file);
                }
            } variation="primary">{loading? <Loader size="small"/> : '📷'}</Button>
        </>
    );
}

export default ImageCaptureButton;
