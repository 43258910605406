function calculateZoomLevel(accuracy) {
    if (accuracy) {
        if (accuracy <= 8) return 18; // Very high accuracy
        if (accuracy <= 16) return 17; // High accuracy
        if (accuracy <= 32) return 16; // Good accuracy
        if (accuracy <= 64) return 15; // Moderate accuracy
        if (accuracy <= 128) return 14; // Low accuracy
        return 13; // Very low accuracy
    }
    else return 12; // No accuracy
}

export default calculateZoomLevel;