import React from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { Authenticator } from '@aws-amplify/ui-react';
import Layout from './Layout';
import LegalLayout from './LegalLayout';
import MapLayout from './MapLayout';
import SignIn from "./SignIn";
import SignInLayout from './SignInLayout';
import Start from './Start';
import Map from './Map';
import './index.css';
import '@aws-amplify/ui-react/styles.css';
import studioTheme from './ui-components/studioTheme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Abuse from './Abuse'
import CustomAuthenticator from './CustomAuthenticator'
import Privacy from './Privacy'
import Delete from './Delete'
import Profile from './Profile'
import Password from './Password'
import SignOut from './SignOut';
import Terms from './Terms'
import Email from './Email';
import ImageUpload from "./ImageUpload";
import Images from "./Images";
import PhoneNumber from './PhoneNumber';

import reportWebVitals from './reportWebVitals';
import ID from "./ID";
import {SessionProvider} from "./SessionContext";

import awsExports from './aws-exports';
import {fetchAuthSession} from "@aws-amplify/auth";

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port? `:${window.location.port}` : '';
const oauthDomain = process.env.REACT_APP_AUTH_DOMAIN ? process.env.REACT_APP_AUTH_DOMAIN : awsExports.oauth.domain;

// https://github.com/aws-amplify/amplify-cli/issues/1880
const updatedAwsExports = {
    ...awsExports,
    oauth: {
        ...awsExports.oauth,
        domain: oauthDomain,
        redirectSignIn: `${protocol}//${hostname}${port}/signin/`,
        redirectSignOut: `${protocol}//${hostname}${port}/signout/`,
        // https://docs.amplify.aws/javascript/build-a-backend/auth/manage-user-session/
        responseType: 'code',
    },
}
export async function prefixResolver({ accessLevel, targetIdentityId }) {
    // Normally accessLevel is 'guest', 'protected', or 'private'
    // but we have overridden it to include the accountId.
    const { level, accountId } = accessLevel;
    if (level) {
        console.debug('accessLevel:', level, 'accountId:', accountId);
        if (level === 'guest') {
            return `public/account/${accountId}/`;
        } else if (level === 'protected') {
            return `protected/account/${accountId}/`;
        } else {
            return `private/account/${accountId}/`;
        }
    }
    else {
        // The override is not in use
        if (accessLevel === 'guest') {
            return `public/guest/`;
        } else if (accessLevel === 'protected') {
            return `protected/identity/${targetIdentityId}/`;
        } else {
            return `private/identity/${targetIdentityId}/`;
        }
    }
}

Amplify.configure(updatedAwsExports, {
    API: {
        GraphQL: {
            defaultAuthMode: 'userPool',
            headers: async () => {
                const authSession = await fetchAuthSession();
                return {
                    // Use the ID token instead of access token,
                    // to enable the API to access the custom claim 'acc'
                    Authorization: authSession?.tokens?.idToken
                };
            }
        },
    },
    // https://docs.amplify.aws/nextjs/build-a-backend/server-side-rendering/?platform=react
    ssr: true,
    Storage: {
        S3: {
            prefixResolver
        }
    }
});

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <SessionProvider>
      <ThemeProvider theme={studioTheme}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Start />} />
              <Route path="delete" element={<Delete />} />
              <Route path="email" element={<Email />} />
              <Route path="password" element={<Password />} />
              <Route path="profile" element={<Profile />} />
              <Route path="ID" element={<ID />} />
              <Route path="image" element={<ImageUpload />} />
              <Route path="images" element={<Images />} />
              <Route path="phone-number" element={<PhoneNumber />} />
              <Route path="signout" element={<SignOut />} />
            </Route>
            <Route path="/" element={<LegalLayout />}>
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms" element={<Terms />} />
                <Route path="abuse" element={<Abuse />} />
            </Route>
            <Route path="map" element={<MapLayout />}>
              <Route index element={<Map />} />
            </Route>
            <Route path="signin" element={<SignInLayout />}>
              <Route index element={<SignIn />} />
              <Route path="authenticator" element={<CustomAuthenticator />} />
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
      </SessionProvider>
    </Authenticator.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
