import {generateClient} from "aws-amplify/api";
import {
    getImage, imagesByAccountIdAndFilename, listImages,
} from "../graphql/queries";
import {
    createImage,
} from "../graphql/mutations";

import {v4 as uuidv4} from 'uuid';
import {handleExifData} from "./exif";
import {uploadData} from "aws-amplify/storage";
import {Amplify} from "aws-amplify";
import {prefixResolver} from "../index";

const client = generateClient({authMode: 'userPool'});

export async function findImagesByAccountId(accountId) {
    console.log(`Loading images for account ID ${accountId}`);
    const variables = { accountId, limit: 20 };
    const result = await client.graphql({
        query: imagesByAccountIdAndFilename,
        variables: variables,
    });
    console.log('Result', result);
    const images = result.data.imagesByAccountIdAndFilename.items;
    return images.filter(Boolean);
}

export async function createImageRecord({accountId, url, bytes, filename, exif}) {
    const response = await client.graphql({
        query: createImage,
        variables: {
            input: {
                id: uuidv4(),
                accountId,
                url,
                bytes,
                filename,
                redactedAt: null,
                exif: JSON.stringify(exif)
            }
        }
    });
    return response.data.createImage;
}

export const uploadImages = async ({accountId, files, location}) => {
    for(const file of files) {
        console.debug('File', JSON.stringify(file));
        const filename = file.name;
        const bytes = file.size;
        const lastModified = file.lastModified;
        // file.lastModifiedDate is deprecated
        const lastModifiedDate = lastModified? new Date(lastModified) : undefined;
        const type = file.type;

        console.log(`user agent location: ${JSON.stringify(location)}`);
        const {
            orientation, pixelXDimension, pixelYDimension,
            gpsDate, originalDate,
            latitude, longitude, altitude,
            imageDirection, destinationBearing
        } = await handleExifData({file, location});

        console.log(`gpsDate: ${gpsDate}, originalDate: ${originalDate}`);
        console.log(`latitude: ${latitude}, longitude: ${longitude}, altitude: ${altitude}, imageDirection: ${imageDirection}, destinationBearing: ${destinationBearing}`);
        const accessLevel = { level: 'private', accountId: accountId }
        try {
            const result = await uploadData({
                key: filename,
                data: file,
                options: {
                    contentType: type,
                    accessLevel,
                    metadata: {
                        filename,
                        lastModifiedDate: lastModifiedDate?.toISOString(),
                        orientation, pixelXDimension, pixelYDimension,
                        gpsDate: gpsDate?.toISOString(), originalDate,
                        latitude, longitude, altitude,
                        imageDirection, destinationBearing
                    }
                }
            }).result;

            const contentType = result.contentType;
            const size = result.size;
            const eTag = result.eTag;
            const key = result.key;
            const metadata = result.metadata;
            const versionId = result.versionId;

            if (contentType !== type) {
                console.warn(`S3 reported content-type '${contentType}' is different from the expected '${type}'`);
            }

            if (size !== bytes) {
                console.warn(`S3 reported size ${size} is different from the expected ${bytes} bytes`);
            }
            console.log('Succeeded: ', result);

            const { bucket, region } = Amplify.getConfig().Storage.S3;
            const prefix = await prefixResolver({accessLevel});
            const url = `https://${bucket}.s3.${region}.amazonaws.com/${prefix}${key}`;
            console.debug('Creating image record: ', accountId, url, bytes, filename, file.exifdata);
            const image = await createImageRecord({
                accountId, url, bytes, filename, exif: file.exifdata
            });
            console.log('Image record: ', image);
        } catch (error) {
            console.log('Error : ', error);
        }
    }
}