import React, {useCallback, useEffect, useState} from 'react';

import {
    Button,
    Card,
    CheckboxField,
    Flex,
    Image,
    Label,
    Loader,
    useAuthenticator,
    Text,
    TextField,
    View, useTheme
} from '@aws-amplify/ui-react';
import {Link, useNavigate} from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import { useSession } from './SessionContext';
import {findImagesByAccountId} from "./utils/images";

const formatDate = (date) => {
    return new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    }).format(new Date(date));
};

const Images = () => {
    const {tokens} = useTheme();

    const {user, userAttributes} = useSession();
    const {accessToken, idToken} = useSession();

    const [images, setImages] = useState([]);

    const navigate = useNavigate();

    const checkImages = useCallback(async () => {
        try {
            if (!idToken) {
                console.debug("No ID token");
                return;
            }
            console.log("Listing images");

            const items = await findImagesByAccountId(idToken.payload.acc);
            setImages(items);

        } catch (error) {
            console.log(error);
        }
    }, [idToken]);

    useEffect(() => {
        checkImages().then();
    }, [checkImages]);

    return (
        <View padding="xxxs xxxs xxl xxxs" gap="xxxs" style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'stretch',
            wordBreak: 'break-all',
            width: '100%', height: '100%',
            overflowY: 'auto',
            backgroundColor: `${tokens.colors.font.secondary}`
        }}>
        {images.map((image, index) => (
            <Card backgroundColor="brand.secondary.40" borderRadius="large"
                  key={index} className="gallery-item"
                  maxWidth="180px" maxHeight="312px">
                <Image maxHeight="192px" width="auto" alt={`Image ${index}`} src={image.url} className="gallery-image"/>
                <CheckboxField name="image" value={'image_' + image.id} label={image.filename}
                fontSize="xs"></CheckboxField>
                <Text fontSize="xs">{formatDate(image.updatedAt)}</Text>
            </Card>
        ))}
        </View>
    );
}

export default Images;
