/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://ukx5xy6xsndpzgc6mv5uancf2i.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-lsfkbu7jdnaxxieljaagegdlo4",
    "aws_cloud_logic_custom": [
        {
            "name": "TodoTikStripe",
            "endpoint": "https://auqmwnyvuh.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:efdccba0-b3ad-4697-905c-fa26be859cfd",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_72ys1yHCP",
    "aws_user_pools_web_client_id": "7ij2plqgt9rnkma7gkevjneg0i",
    "oauth": {
        "domain": "todotik-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev.todotik.com/signin/,http://localhost:3000/signin/",
        "redirectSignOut": "https://dev.todotik.com/signout/,http://localhost:3000/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "todotik-content181301-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "geo": {
        "amazon_location_service": {
            "region": "eu-west-1",
            "search_indices": {
                "items": [
                    "TodoTikIndex-dev"
                ],
                "default": "TodoTikIndex-dev"
            },
            "maps": {
                "items": {
                    "hereHybrid-dev": {
                        "style": "HybridHereExploreSatellite"
                    }
                },
                "default": "hereHybrid-dev"
            }
        }
    }
};


export default awsmobile;
