import React from 'react';

import '@aws-amplify/ui-react-geo/styles.css';

import { useMap } from 'react-map-gl';
import {Button, useTheme} from '@aws-amplify/ui-react';

import { useSession } from './SessionContext';
import calculateZoomLevel from "./utils/location";

function RecenterButton() {
    const { tokens } = useTheme();
    const { current: map } = useMap();
    const { location } = useSession();

    const flyToLocation = async () => {
        if (location) {
            console.info(`Centering to lat,lon ${location.latitude},${location.longitude} with accuracy ${location.accuracy}`);
            map.flyTo({center: [location.longitude, location.latitude], zoom: calculateZoomLevel(location.accuracy), speed: 0.3});
        }
        else {
            console.error('No location available');
        }
    };

    return <Button borderRadius="large"
        onClick={flyToLocation} fontSize="xl" style={{ lineHeight: `${tokens.fontSizes.xl}` }}
                   padding="zero xxxs xs xxxs"
                   backgroundColor="brand.primary.80" color="white">⌖</Button>;
}

export default RecenterButton;