import EXIF from "exif-js";

function getExifData(imageFile) {
    return new Promise((resolve, reject) => {
        try {
            EXIF.getData(imageFile, function() {
                const allMetaData = EXIF.getAllTags(this);
                resolve(allMetaData);
            });
        } catch (error) {
            reject(error);
        }
    });
}

function convertDMSToDecimal(degrees, minutes, seconds, direction) {
    let decimal = parseFloat((degrees + minutes / 60 + seconds / 3600).toFixed(7));
    // South latitudes and West longitudes should be negative values
    if (direction === 'S' || direction === 'W') {
        decimal *= -1;
    }
    return decimal;
}

export async function handleExifData({file, location}) {
    try {
        await getExifData(file);

        // Remove metadata that we don't process

        delete file.exifdata.MakerNote;
        delete file.exifdata.thumbnail;
        delete file.exifdata.undefined;

        // Orientation and dimensions

        const orientations = {
            1: 'normal',
            2: 'mirrored',
            3: 'upside down',
            4: 'upside down, mirrored',
            5: '90 degrees',
            6: '90 degrees, mirrored',
            7: '270 degrees',
            8: '270 degrees, mirrored'
        };

        const orientation = file.exifdata.Orientation;
        const pixelXDimension = file.exifdata.PixelXDimension;
        const pixelYDimension = file.exifdata.PixelYDimension;
        console.log(`Orientation: ${orientation} (${ orientations[orientation] || 'UNKNOWN'
        }), X: ${pixelXDimension}, Y: ${pixelYDimension}`)

        // Date and time

        // EXIF does not have time zone information for DateTimeOriginal
        const dateTimeOriginal = file.exifdata.DateTimeOriginal;
        const originalDate = file.exifdata.DateTimeOriginal? dateTimeOriginal.replace(/^(\d{4}):(\d{2}):(\d{2})\s/, '$1-$2-$3T').trim() : undefined;
        // Assuming that the original date, if available, is in the local time zone;
        // otherwise, use the current date
        const localDate = originalDate? new Date(originalDate) : Date.now();

        const gpsDateStamp = file.exifdata.GPSDateStamp;
        const gpsTimeStamp = file.exifdata.GPSTimeStamp;

        const [year, month, day] = gpsDateStamp? (
            gpsDateStamp.split(":").map((num, index) => index === 1 ?
                parseInt(num, 10) - 1
                : parseInt(num, 10))
        ) : [undefined, undefined, undefined];

        // GPS date and time stamps are explicitly in the UTC time zone
        const gpsDate = gpsDateStamp?
            new Date(Date.UTC(year, month, day, ...gpsTimeStamp)) : location.timestamp? new Date(location.timestamp) : undefined;

        const msInQuarterHour = 15 * 60 * 1000;
        if (gpsDate && localDate) {
            const locationFixAge = localDate - gpsDate;
            if (locationFixAge > msInQuarterHour || locationFixAge < 0) {
                console.warn(`Original date may be in a different time zone`);
            }
            console.log(`GPS fix date: ${gpsDate.toISOString()}, original date: ${originalDate
            }, location fix age: ${(localDate - gpsDate)/1000}s`);
        }

        // Geolocation

        const lat = file.exifdata.GPSLatitude;
        const latRef = file.exifdata.GPSLatitudeRef;
        const latitude = lat? convertDMSToDecimal(...lat, latRef) : location.latitude || undefined;

        const lon = file.exifdata.GPSLongitude;
        const lonRef = file.exifdata.GPSLongitudeRef;
        const longitude = lon? convertDMSToDecimal(...lon, lonRef) : location.longitude || undefined;

        const alt = file.exifdata.GPSAltitude;
        const altRef = file.exifdata.GPSAltitudeRef;
        const altitude = alt? parseFloat((altRef === 0? alt : -alt).toFixed(2)) : location.altitude || undefined;

        if (latitude || longitude || altitude) {
            console.log(`GPS latitude, longitude: ${latitude}, ${longitude}, altitude: ${altitude}`);
        }

        // Direction

        const imageDirection = file.exifdata.GPSImgDirection? parseFloat(file.exifdata.GPSImgDirection.toFixed(1)) : undefined;
        if (imageDirection) {
            const gpsImgDirectionRef = file.exifdata.GPSImgDirectionRef;
            if (gpsImgDirectionRef && (gpsImgDirectionRef !== 'T')) {
                console.warn(`GPS image direction ${imageDirection} reference '${gpsImgDirectionRef}' is different from true north 'T'`);
            }
        }

        const destinationBearing = file.exifdata.GPSDestBearing? parseFloat(file.exifdata.GPSDestBearing.toFixed(1)) : location.heading || undefined;
        if (destinationBearing) {
            const gpsDestBearingRef = file.exifdata.GPSDestBearingRef;
            if (gpsDestBearingRef && (gpsDestBearingRef !== 'T')) {
                console.warn(`GPS destination bearing ${destinationBearing} reference '${gpsDestBearingRef}' is different from true north 'T'`);
            }
        }

        console.log(`JSON EXIF data length: ${JSON.stringify(file.exifdata).length}`);
        return {
            orientation, pixelXDimension, pixelYDimension,
            gpsDate, originalDate,
            latitude, longitude, altitude,
            imageDirection, destinationBearing
        };
    } catch (error) {
        console.error('Error reading EXIF data:', error);
        return {};
    }
}