/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccountPhone = /* GraphQL */ `
  mutation CreateAccountPhone(
    $input: CreateAccountPhoneInput!
    $condition: ModelAccountPhoneConditionInput
  ) {
    createAccountPhone(input: $input, condition: $condition) {
      id
      accountId
      label
      phoneNumber
      sms
      verifiedAt
      detachedAt
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAccountPhone = /* GraphQL */ `
  mutation UpdateAccountPhone(
    $input: UpdateAccountPhoneInput!
    $condition: ModelAccountPhoneConditionInput
  ) {
    updateAccountPhone(input: $input, condition: $condition) {
      id
      accountId
      label
      phoneNumber
      sms
      verifiedAt
      detachedAt
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAccountPhone = /* GraphQL */ `
  mutation DeleteAccountPhone(
    $input: DeleteAccountPhoneInput!
    $condition: ModelAccountPhoneConditionInput
  ) {
    deleteAccountPhone(input: $input, condition: $condition) {
      id
      accountId
      label
      phoneNumber
      sms
      verifiedAt
      detachedAt
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAccountEmail = /* GraphQL */ `
  mutation CreateAccountEmail(
    $input: CreateAccountEmailInput!
    $condition: ModelAccountEmailConditionInput
  ) {
    createAccountEmail(input: $input, condition: $condition) {
      id
      accountId
      email
      label
      verifiedAt
      detachedAt
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAccountEmail = /* GraphQL */ `
  mutation UpdateAccountEmail(
    $input: UpdateAccountEmailInput!
    $condition: ModelAccountEmailConditionInput
  ) {
    updateAccountEmail(input: $input, condition: $condition) {
      id
      accountId
      email
      label
      verifiedAt
      detachedAt
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAccountEmail = /* GraphQL */ `
  mutation DeleteAccountEmail(
    $input: DeleteAccountEmailInput!
    $condition: ModelAccountEmailConditionInput
  ) {
    deleteAccountEmail(input: $input, condition: $condition) {
      id
      accountId
      email
      label
      verifiedAt
      detachedAt
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createIdDocument = /* GraphQL */ `
  mutation CreateIdDocument(
    $input: CreateIdDocumentInput!
    $condition: ModelIdDocumentConditionInput
  ) {
    createIdDocument(input: $input, condition: $condition) {
      id
      accountId
      expires
      issued
      issuingCountry
      number
      type
      verifiedAt
      vsId
      imageIds
      status
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateIdDocument = /* GraphQL */ `
  mutation UpdateIdDocument(
    $input: UpdateIdDocumentInput!
    $condition: ModelIdDocumentConditionInput
  ) {
    updateIdDocument(input: $input, condition: $condition) {
      id
      accountId
      expires
      issued
      issuingCountry
      number
      type
      verifiedAt
      vsId
      imageIds
      status
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteIdDocument = /* GraphQL */ `
  mutation DeleteIdDocument(
    $input: DeleteIdDocumentInput!
    $condition: ModelIdDocumentConditionInput
  ) {
    deleteIdDocument(input: $input, condition: $condition) {
      id
      accountId
      expires
      issued
      issuingCountry
      number
      type
      verifiedAt
      vsId
      imageIds
      status
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVerificationSessionIdDocument = /* GraphQL */ `
  mutation CreateVerificationSessionIdDocument(
    $input: CreateVerificationSessionIdDocumentInput!
    $condition: ModelVerificationSessionIdDocumentConditionInput
  ) {
    createVerificationSessionIdDocument(input: $input, condition: $condition) {
      id
      documentId
      vsId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVerificationSessionIdDocument = /* GraphQL */ `
  mutation UpdateVerificationSessionIdDocument(
    $input: UpdateVerificationSessionIdDocumentInput!
    $condition: ModelVerificationSessionIdDocumentConditionInput
  ) {
    updateVerificationSessionIdDocument(input: $input, condition: $condition) {
      id
      documentId
      vsId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVerificationSessionIdDocument = /* GraphQL */ `
  mutation DeleteVerificationSessionIdDocument(
    $input: DeleteVerificationSessionIdDocumentInput!
    $condition: ModelVerificationSessionIdDocumentConditionInput
  ) {
    deleteVerificationSessionIdDocument(input: $input, condition: $condition) {
      id
      documentId
      vsId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPersonalIdDocument = /* GraphQL */ `
  mutation CreatePersonalIdDocument(
    $input: CreatePersonalIdDocumentInput!
    $condition: ModelPersonalIdDocumentConditionInput
  ) {
    createPersonalIdDocument(input: $input, condition: $condition) {
      id
      personalId
      documentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePersonalIdDocument = /* GraphQL */ `
  mutation UpdatePersonalIdDocument(
    $input: UpdatePersonalIdDocumentInput!
    $condition: ModelPersonalIdDocumentConditionInput
  ) {
    updatePersonalIdDocument(input: $input, condition: $condition) {
      id
      personalId
      documentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePersonalIdDocument = /* GraphQL */ `
  mutation DeletePersonalIdDocument(
    $input: DeletePersonalIdDocumentInput!
    $condition: ModelPersonalIdDocumentConditionInput
  ) {
    deletePersonalIdDocument(input: $input, condition: $condition) {
      id
      personalId
      documentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPersonalId = /* GraphQL */ `
  mutation CreatePersonalId(
    $input: CreatePersonalIdInput!
    $condition: ModelPersonalIdConditionInput
  ) {
    createPersonalId(input: $input, condition: $condition) {
      id
      accountId
      issuer
      idNumberType
      idNumber
      status
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePersonalId = /* GraphQL */ `
  mutation UpdatePersonalId(
    $input: UpdatePersonalIdInput!
    $condition: ModelPersonalIdConditionInput
  ) {
    updatePersonalId(input: $input, condition: $condition) {
      id
      accountId
      issuer
      idNumberType
      idNumber
      status
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePersonalId = /* GraphQL */ `
  mutation DeletePersonalId(
    $input: DeletePersonalIdInput!
    $condition: ModelPersonalIdConditionInput
  ) {
    deletePersonalId(input: $input, condition: $condition) {
      id
      accountId
      issuer
      idNumberType
      idNumber
      status
      redactedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFederatedId = /* GraphQL */ `
  mutation CreateFederatedId(
    $input: CreateFederatedIdInput!
    $condition: ModelFederatedIdConditionInput
  ) {
    createFederatedId(input: $input, condition: $condition) {
      id
      accountId
      username
      provider
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFederatedId = /* GraphQL */ `
  mutation UpdateFederatedId(
    $input: UpdateFederatedIdInput!
    $condition: ModelFederatedIdConditionInput
  ) {
    updateFederatedId(input: $input, condition: $condition) {
      id
      accountId
      username
      provider
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFederatedId = /* GraphQL */ `
  mutation DeleteFederatedId(
    $input: DeleteFederatedIdInput!
    $condition: ModelFederatedIdConditionInput
  ) {
    deleteFederatedId(input: $input, condition: $condition) {
      id
      accountId
      username
      provider
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      accOwn
      sub
      birthdate
      email
      familyName
      givenName
      gender
      imageId
      locale
      middleName
      name
      nickname
      username
      zoneInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      accOwn
      sub
      birthdate
      email
      familyName
      givenName
      gender
      imageId
      locale
      middleName
      name
      nickname
      username
      zoneInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      accOwn
      sub
      birthdate
      email
      familyName
      givenName
      gender
      imageId
      locale
      middleName
      name
      nickname
      username
      zoneInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAccountStatus = /* GraphQL */ `
  mutation CreateAccountStatus(
    $input: CreateAccountStatusInput!
    $condition: ModelAccountStatusConditionInput
  ) {
    createAccountStatus(input: $input, condition: $condition) {
      id
      accountId
      lastSignInAt
      licenseStatus
      redactedAt
      signInAttemptAt
      signInAttempts
      suspendedAt
      suspendedReason
      verificationAttemptAt
      verificationAttempts
      verifiedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAccountStatus = /* GraphQL */ `
  mutation UpdateAccountStatus(
    $input: UpdateAccountStatusInput!
    $condition: ModelAccountStatusConditionInput
  ) {
    updateAccountStatus(input: $input, condition: $condition) {
      id
      accountId
      lastSignInAt
      licenseStatus
      redactedAt
      signInAttemptAt
      signInAttempts
      suspendedAt
      suspendedReason
      verificationAttemptAt
      verificationAttempts
      verifiedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAccountStatus = /* GraphQL */ `
  mutation DeleteAccountStatus(
    $input: DeleteAccountStatusInput!
    $condition: ModelAccountStatusConditionInput
  ) {
    deleteAccountStatus(input: $input, condition: $condition) {
      id
      accountId
      lastSignInAt
      licenseStatus
      redactedAt
      signInAttemptAt
      signInAttempts
      suspendedAt
      suspendedReason
      verificationAttemptAt
      verificationAttempts
      verifiedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVerifiedSensitiveData = /* GraphQL */ `
  mutation CreateVerifiedSensitiveData(
    $input: CreateVerifiedSensitiveDataInput!
    $condition: ModelVerifiedSensitiveDataConditionInput
  ) {
    createVerifiedSensitiveData(input: $input, condition: $condition) {
      id
      accountId
      vsId
      expiresAt
      redactedAt
      birthdate
      idNumber
      idNumberType
      document
      documentImages
      faceImages
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVerifiedSensitiveData = /* GraphQL */ `
  mutation UpdateVerifiedSensitiveData(
    $input: UpdateVerifiedSensitiveDataInput!
    $condition: ModelVerifiedSensitiveDataConditionInput
  ) {
    updateVerifiedSensitiveData(input: $input, condition: $condition) {
      id
      accountId
      vsId
      expiresAt
      redactedAt
      birthdate
      idNumber
      idNumberType
      document
      documentImages
      faceImages
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVerifiedSensitiveData = /* GraphQL */ `
  mutation DeleteVerifiedSensitiveData(
    $input: DeleteVerifiedSensitiveDataInput!
    $condition: ModelVerifiedSensitiveDataConditionInput
  ) {
    deleteVerifiedSensitiveData(input: $input, condition: $condition) {
      id
      accountId
      vsId
      expiresAt
      redactedAt
      birthdate
      idNumber
      idNumberType
      document
      documentImages
      faceImages
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      accountId
      url
      bytes
      filename
      redactedAt
      exif
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      accountId
      url
      bytes
      filename
      redactedAt
      exif
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      accountId
      url
      bytes
      filename
      redactedAt
      exif
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVerificationSession = /* GraphQL */ `
  mutation CreateVerificationSession(
    $input: CreateVerificationSessionInput!
    $condition: ModelVerificationSessionConditionInput
  ) {
    createVerificationSession(input: $input, condition: $condition) {
      id
      vsId
      cognitoIdentityId
      accountId
      email
      livemode
      type
      status
      redactedAt
      lastError
      lastVerificationReport
      phoneNumber
      givenNames
      familyNames
      address
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVerificationSession = /* GraphQL */ `
  mutation UpdateVerificationSession(
    $input: UpdateVerificationSessionInput!
    $condition: ModelVerificationSessionConditionInput
  ) {
    updateVerificationSession(input: $input, condition: $condition) {
      id
      vsId
      cognitoIdentityId
      accountId
      email
      livemode
      type
      status
      redactedAt
      lastError
      lastVerificationReport
      phoneNumber
      givenNames
      familyNames
      address
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVerificationSession = /* GraphQL */ `
  mutation DeleteVerificationSession(
    $input: DeleteVerificationSessionInput!
    $condition: ModelVerificationSessionConditionInput
  ) {
    deleteVerificationSession(input: $input, condition: $condition) {
      id
      vsId
      cognitoIdentityId
      accountId
      email
      livemode
      type
      status
      redactedAt
      lastError
      lastVerificationReport
      phoneNumber
      givenNames
      familyNames
      address
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
