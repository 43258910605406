import React from 'react';

import {Card, Flex, Text, View} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import ImageCaptureButton from "./ImageCaptureButton";
import ImagesUploadButton from "./ImagesUploadButton";

const ImageUpload = () => {
    return (
        <View
            overflow="hidden auto"
            width="100" height="100%"
            padding="zero zero xs xs"
            backgroundColor="font.secondary" marginTop="zero">
            <Flex width="100%" direction="column" gap="xs" marginTop="zero">
                <Card backgroundColor="brand.primary.80" marginBottom="xl" borderRadius="xl 0 0 xl">
                    <Text>Testing image capture and upload</Text>
                    <ImageCaptureButton/>
                    <ImagesUploadButton/>
                </Card>
            </Flex>
        </View>
    );
}

export default ImageUpload;
